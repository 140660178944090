import Button from '../common/Button';

interface Props {
  store: any;
  productUrl: string;
}

export default function BuyFooter({ store, productUrl }: Props) {
  return (
    <div className="fixed shadow-[0px_0px_5px_0px_#00000024] h-24 bottom-0 left-0 w-full bg-white p-4 flex justify-center items-center z-50">
      <Button
        id="product-page-buy-footer-button-buy-from-store"
        title={`Buy now at ${store}`}
        onClick={() => {
          window.open(`${productUrl}`, '_blank');
        }}
      />
    </div>
  );
}
