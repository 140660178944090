import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import { Product } from '../lib/types';
import { db } from '../lib/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { HiArrowLongLeft } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import Compare from '../components/Compare';
import CompareFooter from '../components/CompareFooter';

const LikedPage = () => {
  const navigate = useNavigate();
  const [likedProducts, setLikedProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [showCompare, setShowCompare] = useState<boolean>(false);
  const isComparing = selectedProducts.length > 0;
  const canCompare = selectedProducts.length === 2;

  const handleSelectProduct = (product: Product) => {
    setSelectedProducts(prev => {
      if (prev.includes(product)) {
        return prev.filter(p => p !== product);
      } else {
        if (prev.length >= 2) {
          return prev;
        }
        return [...prev, product];
      }
    });
  };

  const handleCompareClick = () => {
    setShowCompare(true);
  };

  const handleCloseCompare = () => {
    setShowCompare(false);
    setSelectedProducts([]);
  };

  const fetchLikedProducts = async () => {
    const likedProductIds =
      JSON.parse(localStorage.getItem('likedProducts') || '[]') || [];
    if (likedProductIds.length > 0) {
      let q = query(collection(db, 'products'));
      q = query(q, where('id', 'in', likedProductIds));

      const snapshot = await getDocs(q);
      const fetchedProducts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Product[];

      setLikedProducts(fetchedProducts);
    }
  };

  useEffect(() => {
    fetchLikedProducts();
  }, []);

  return (
    <div className="container mx-auto pb-16 px-4">
      <h1 className="text-3xl font-serif mt-4 mb-4 text-center">
        Your saved products
      </h1>
      <div className="mt-2 grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 gap-4">
        {likedProducts.map(product => (
          <Link to={`/product/${product.id}`}>
            <Card
              key={product.id}
              product={product}
              isSelected={selectedProducts.includes(product)}
              handleSelectProduct={handleSelectProduct}
            />
          </Link>
        ))}
      </div>

      {showCompare && (
        <Compare products={selectedProducts} handleClose={handleCloseCompare} />
      )}
      {isComparing && (
        <CompareFooter
          selectedProducts={selectedProducts.length}
          canStartComparing={canCompare}
          startComparing={handleCompareClick}
          clearSelectedProducts={() => setSelectedProducts([])}
        />
      )}
    </div>
  );
};

export default LikedPage;
