import React, { LegacyRef, MutableRefObject } from 'react';
import Card from '../Card';
import { Product } from '../../lib/types';
import { Link } from 'react-router-dom';

interface Props {
  products: Product[];
  selectedProducts: Product[];
  handleSelectProduct: (product: Product) => void;
  scrollTop: MutableRefObject<number>;
}

const Grid = ({
  products,
  selectedProducts,
  handleSelectProduct,
  scrollTop,
}: Props) => {
  return (
    <div className="flex flex-wrap">
      {products.map((product, index) => {
        return (
          <div
            key={index}
            className="flex w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 p-2"
          >
            <Link
              to={`/product/${product.id}`}
              onClick={e => (scrollTop.current = window.scrollY)}
            >
              <Card
                product={product}
                handleSelectProduct={handleSelectProduct}
                isSelected={selectedProducts.some(p => p.id === product.id)}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
