import React, { useEffect, useState } from 'react';
import Button from '../components/common/Button';
import { Link } from 'react-router-dom';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Product } from '../lib/types';
import { HiArrowLongRight } from 'react-icons/hi2';

const paragraphs = [
  'Stop overpaying for luxury bags',
  'The best designer deals, unlocked',
  'Compare 3000+ vintage bags from multiple shops',
  'All in one place',
];

const LatestProducts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      const q = query(
        collection(db, 'products'),
        orderBy('createdAt', 'desc'),
        where('sold', '==', false),
        limit(8)
      );
      const querySnapshot = await getDocs(q);
      const productsArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsArray as Product[]);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return (
    <section className="bg-background py-8">
      <div className="container mx-auto px-4">
        <Link to="/products?sort=createdAt" reloadDocument>
          <div className="flex justify-between px-4 items-center">
            <h2 className="text-3xl font-bold font-serif mb-4">New In</h2>
            <span className="flex items-center mb-4">
              <h2 className="text-lg font-bold font-serif">View all</h2>
              <HiArrowLongRight className="text-xl ml-2 inline-block" />
            </span>
          </div>
        </Link>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          {products.map(product => (
            <Link key={product.id} to={`/product/${product.id}`}>
              <div className="flex flex-col p-4">
                <img
                  src={product.imageUrl}
                  alt={product.brand + ' ' + product.model}
                  className="w-full aspect-square object-cover mb-4 rounded-md"
                />
                <h3 className="font-serif font-medium text-[20px] uppercase">
                  {product.brand}
                </h3>
                <h3 className="font-sans text-[14px]">{product.model}</h3>
                <p className="font-sans font-bold text-[14px]">
                  {parseFloat(product.price).toLocaleString('se-SE') +
                    ' ' +
                    product.currency}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

const DiscountedProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchDiscountedProducts = async () => {
      let q = query(
        collection(db, 'products'),
        where('discount', '>', 0),
        where('sold', '==', false),
        orderBy('createdAt', 'desc'),
        limit(8)
      );
      const querySnapshot = await getDocs(q);
      const productsArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsArray as Product[]);
    };

    fetchDiscountedProducts();
  }, []);

  return (
    <section className="bg-background py-8">
      <div className="container mx-auto px-4">
        <Link to="/products?discounted=true" reloadDocument>
          <div className="flex justify-between px-4 items-center">
            <h2 className="text-3xl font-bold font-serif mb-4">On Sale</h2>
            <span className="flex items-center mb-4">
              <h2 className="text-lg font-bold font-serif">View all</h2>
              <HiArrowLongRight className="text-xl ml-2 inline-block" />
            </span>
          </div>
        </Link>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          {products.map(product => (
            <Link key={product.id} to={`/product/${product.id}`}>
              <div className="flex flex-col p-4">
                <img
                  src={product.imageUrl}
                  alt={product.brand + ' ' + product.model}
                  className="w-full aspect-square object-cover mb-4 rounded-md"
                />
                <h3 className="font-serif font-medium text-[20px] uppercase">
                  {product.brand}
                </h3>
                <h3 className="font-sans text-[14px]">{product.model}</h3>
                <div className="font-sans font-bold text-[14px]">
                  <div className="text-gray-500 line-through">
                    {parseFloat(product.price).toLocaleString('se-SE') +
                      ' ' +
                      product.currency}
                  </div>
                  <div className="text-red-500 mr-2">
                    {parseFloat(product.discount).toLocaleString('se-SE') +
                      ' ' +
                      product.currency}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

const ScrollingBrands = () => {
  const brands = [
    'Gucci',
    'Prada',
    'Louis Vuitton',
    'Chanel',
    'Versace',
    'Dior',
    'Fendi',
    'Hermès',
    'Balenciaga',
    'Burberry',
  ];

  return (
    <div className="relative flex bg-white border-gray-200 py-2 overflow-hidden">
      <div className="whitespace-nowrap animate-marquee">
        {brands.concat(brands).map((brand, index) => (
          <span key={index} className="text-gray-500 font-serif text-2xl mx-8">
            {brand}
          </span>
        ))}
      </div>
      <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap">
        {brands.concat(brands).map((brand, index) => (
          <span key={index} className="text-gray-500 font-serif text-2xl mx-8">
            {brand}
          </span>
        ))}
      </div>
    </div>
  );
};

const LandingPage = () => {
  const [currentParagraph, setCurrentParagraph] = useState<number>(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentParagraph(prev => (prev + 1) % paragraphs.length);
        setFadeIn(true);
      }, 500); // Duration of fade-out
    }, 3000); // Duration of each paragraph display

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div
        className="relative w-screen aspect-square sm:aspect-auto sm:h-96 bg-cover bg-center pt-8"
        style={{ backgroundImage: "url('/landing.jpg')" }}
      >
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="relative z-10 flex flex-col justify-between h-full text-center text-white px-4 py-8">
          <div className="h-24 flex items-center justify-center">
            <h1 className="font-serif text-white text-4xl md:text-6xl mt-24 font-bold">
              The new way of buying vintage luxury
            </h1>
          </div>
          <div className="flex flex-col justify-center flex-grow overflow-hidden">
            <h1
              className={`font-serif text-white text-2xl md:text-4xl mb-4 transition-opacity duration-500 ${
                fadeIn ? 'animate-fadeInOut' : 'opacity-0'
              }`}
            >
              {paragraphs[currentParagraph]}
            </h1>
          </div>
          <div className="flex justify-center mb-4">
            <Link to="/products">
              <div className="bg-transparent border border-white text-white rounded-md p-2 px-4 font-sans">
                Start comparing
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* <section className="bg-background py-8 mx-4"> */}
      <div className="flex flex-col  mx-auto px-4 bg-white  text-center h-72 items-center justify-center">
        <h2 className="text-3xl font-bold font-serif pt-4 mb-2">
          Luxury within reach
        </h2>
        <p className="text-md pb-6 prose">
          We connect you with the best preloved designer bags from trusted shops
          worldwide, making it easy to compare prices, conditions and styles in
          one place. Our mission is to bring you accessible luxury while
          promoting sustainable fashion.
        </p>
      </div>
      {/* </section> */}

      <LatestProducts />

      {/* <ScrollingBrands /> */}

      {/* <section className="py-8 mx-4"> */}
      <div className="relative w-full h-72">
        <img
          src="/landing02.jpg"
          alt="Description of the image"
          className="w-full h-full object-cover brightness-50"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2 p-4">
          <p className="text-md pb-6 text-white font-serif text-4xl text-center">
            Explore 3000+ products
          </p>
          <Link to="/products" reloadDocument>
            <div className="bg-transparent border border-white text-white  rounded-md p-2 px-4 font-sans">
              See all products
            </div>
          </Link>
        </div>
      </div>
      {/* </section> */}

      <DiscountedProducts />
    </div>
  );
};

export default LandingPage;
