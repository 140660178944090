import {
  collection,
  getDocs,
  limit,
  query as firestoreQuery,
  where,
  doc,
  getDoc,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { db } from '../lib/firebase';
import Spinner from '../components/Spinner';
import { RiHeart3Fill, RiHeartAdd2Line } from 'react-icons/ri';
import Carousel from '../components/Carousel';
import BuyFooter from '../components/product/BuyFooter';
import Button from '../components/common/Button';
import { HiArrowLongRight } from 'react-icons/hi2';
import { Product } from '../lib/types';
import {
  createQueryFromSearchParams,
  defaultQuery,
  Query,
  search,
} from '../lib/search';

interface InfoProps {
  label: string;
  text: string;
}

const Info = ({ label, text }: InfoProps) => {
  return (
    <div className="border-b mt-4 pb-2">
      <p className="font-sans text-lg font-bold">{label}</p>
      <p className="font-sans text-md">{text}</p>
    </div>
  );
};

const SimilarProducts = ({ product }: { product: Product }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const productsPerPage = 8;

  const fetchData = async () => {
    let query: Query = { ...defaultQuery };
    query.search = product.brand + ' ' + product.model;
    const { hits: ids, totalPages } = await search(query, 1, productsPerPage);

    const productsQuery = firestoreQuery(
      collection(db, 'products'),
      where('__name__', 'in', ids),
      limit(productsPerPage)
    );
    const snapshot = await getDocs(productsQuery);
    const products = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    })) as Product[];
    setProducts(products);
  };

  useEffect(() => {
    fetchData();
  }, [product]);

  return (
    <section className="bg-background py-8">
      <div className="container mx-auto px-4">
        <Link
          to={`/products?search=${product.brand + ' ' + product.model}`}
          reloadDocument
        >
          <div className="flex justify-between px-4 items-center">
            <h2 className="text-3xl font-bold font-serif mb-4">
              Similar products
            </h2>
            <span className="flex items-center mb-4">
              <h2 className="text-lg font-bold font-serif">View all</h2>
              <HiArrowLongRight className="text-xl ml-2 inline-block" />
            </span>
          </div>
        </Link>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          {products.map(product => (
            <Link
              key={product.id}
              to={`/product/${product.id}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="flex flex-col p-4">
                <img
                  src={product.imageUrl}
                  alt={product.brand + ' ' + product.model}
                  className="w-full aspect-square object-cover mb-4 rounded-md"
                />
                <h3 className="font-serif font-medium text-[20px] uppercase">
                  {product.brand}
                </h3>
                <h3 className="font-sans text-[14px]">{product.model}</h3>
                <div className="font-sans font-bold text-[14px]">
                  {parseInt(product.discount) > 0 ? (
                    <>
                      <div className="text-gray-500 line-through">
                        {parseFloat(product.price).toLocaleString('se-SE') +
                          ' ' +
                          product.currency}
                      </div>
                      <div className="text-red-500 mr-2">
                        {parseFloat(product.discount).toLocaleString('se-SE') +
                          ' ' +
                          product.currency}
                      </div>
                    </>
                  ) : (
                    <div className="">
                      {parseFloat(product.price).toLocaleString('se-SE') +
                        ' ' +
                        product.currency}
                    </div>
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

const ProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState<any>(null);
  const [showFooter, setShowFooter] = useState(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleHeart = () => {
    const likedProducts: string[] =
      JSON.parse(localStorage.getItem('likedProducts') || '[]') || [];
    if (isLiked) {
      const updatedProducts = likedProducts.filter(id => id !== product.id);
      localStorage.setItem('likedProducts', JSON.stringify(updatedProducts));
    } else {
      likedProducts.push(product.id);
      localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
    }
    setIsLiked(!isLiked);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      const docRef = doc(db, 'products', productId || '');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setProduct(docSnap.data());
        const likedProducts =
          JSON.parse(localStorage.getItem('likedProducts') || '[]') || [];
        setIsLiked(likedProducts.includes(productId));
      } else {
        console.log('No such document!');
      }
    };

    fetchProduct();
  }, [productId]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowFooter(scrollY > 200); // Show footer when scrolled 200px down
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!product) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.model,
    image: product.imageUrl,
    //"description": product.description,
    brand: {
      '@type': 'Brand',
      name: product.brand,
    },
    offers: {
      '@type': 'Offer',
      url: `https://www.avenura.com/product/${product.id}`,
      priceCurrency: product.currency,
      price: product.price,
    },
  };

  return (
    <div className="pb-4">
      <Helmet>
        <title>{'AVENURA - ' + product.brand + ' ' + product.model}</title>
        <meta
          name="description"
          content={product.description || 'AVENURA - The Designer Marketplace'}
        />
        <meta
          property="og:title"
          content={product.brand + ' ' + product.model}
        />
        <meta
          property="og:description"
          content={product.description || 'AVENURA - The Designer Marketplace'}
        />
        <meta property="og:image" content={product.imageUrl} />
        <link
          rel="canonical"
          href={`https://www.avenura.com/product/${product.id}`}
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="flex flex-col lg:flex-row mt-4">
        <div className="relative w-full lg:w-1/2 aspect-square px-4">
          <Carousel images={[product.imageUrl, ...product.imageUrls]}>
            <button
              className={`absolute bottom-2 right-2 ${
                isLiked ? 'text-heartColor' : 'text-black'
              } bg-white rounded-full p-2 drop-shadow hover:border border-gray-400 z-50`}
              onClick={toggleHeart}
            >
              {isLiked ? (
                <div>
                  <RiHeart3Fill className="text-xl" />
                </div>
              ) : (
                <RiHeartAdd2Line className="text-xl" />
              )}
            </button>
            {product.sold && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-3xl font-bold">
                SOLD OUT
              </div>
            )}
          </Carousel>
        </div>
        <div className="w-full lg:w-1/2 lg:pl-5 lg:mt-0 px-4">
          <div className="">
            <div className="font-serif text-2xl font-bold">
              {product.brand}, {product.model}
            </div>
            <div className="">{product.condition} Condition</div>
            {product.discount === 0 ? (
              <div className="">
                {parseFloat(product.price).toLocaleString('se-SE') +
                  ' ' +
                  product.currency}
              </div>
            ) : (
              <div>
                <span className="text-red-500 mr-2">
                  {parseFloat(product.discount).toLocaleString('se-SE') +
                    ' ' +
                    product.currency}
                </span>
                <span className="line-through decoration-1">
                  {parseFloat(product.price).toLocaleString('se-SE') +
                    ' ' +
                    product.currency}
                </span>
              </div>
            )}
            {!product.sold && (
              <div className="mt-6 flex justify-center sm:justify-start">
                <Button
                  id="product-page-button-buy-from-store"
                  title={`Buy now at ${product.store}`}
                  onClick={() => {
                    window.open(`${product.productUrl}`, '_blank');
                  }}
                />
              </div>
            )}
          </div>

          <div className="mt-6">
            <h1 className="font-serif text-2xl font-bold">Product Details</h1>
            <div className="mt-2">
              <h2 className="font-sans font-bold">Color and Material</h2>
              <p>
                {product.color ? product.color + ', ' : ''}
                {product.material ?? ''}
              </p>
            </div>
            <div className="mt-2">
              <h2 className="font-sans font-bold">Measurements</h2>
              {product.length && (
                <p className="font-sans text-md">Length: {product.length} cm</p>
              )}
              {product.width && (
                <p className="font-sans text-md">Width: {product.width} cm</p>
              )}
              {product.height && (
                <p className="font-sans text-md">Height: {product.height} cm</p>
              )}
            </div>
          </div>

          <div className=" mt-4 pb-2">
            <p className="font-sans text-lg font-bold">Description</p>
            <p className="font-sans text-md">
              {product.description ?? 'Description not available'}
            </p>
            {product.comesWith ? (
              <p className="font-sans text-md mt-2">
                Comes with{' '}
                <span className="lowercase">{product.comesWith}</span>.
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <SimilarProducts product={product} />

      {showFooter && !product.sold && (
        <BuyFooter store={product.store} productUrl={product.productUrl} />
      )}
    </div>
  );
};

export default ProductPage;
