import React from 'react';
import { Query } from '../../lib/search';
import FilterPill from './FilterPill';

interface Props {
  query: Query;
  setQuery: (query: Query) => void;
}

export default function FilterPills({ query, setQuery }: Props) {
  return (
    <div>
      {query.brands.map((filter, index) => (
        <FilterPill
          key={index}
          label={filter.label}
          onRemove={() => {
            const updatedFilters = query.brands.filter((_, i) => i !== index);
            setQuery({ ...query, brands: updatedFilters });
          }}
        />
      ))}
      {query.conditions.map((filter, index) => (
        <FilterPill
          key={index}
          label={`${filter.label}`}
          onRemove={() => {
            const updatedFilters = query.conditions.filter(
              (_, i) => i !== index
            );
            setQuery({ ...query, conditions: updatedFilters });
          }}
        />
      ))}
      {query.maxPrice && (
        <FilterPill
          label={`max ${query.maxPrice} SEK`}
          onRemove={() => {
            setQuery({ ...query, maxPrice: '' });
          }}
        />
      )}
      {query.minPrice && (
        <FilterPill
          label={`min ${query.minPrice} SEK`}
          onRemove={() => {
            setQuery({ ...query, minPrice: '' });
          }}
        />
      )}
      {query.isDiscounted && (
        <FilterPill
          label={'On sale'}
          onRemove={() => {
            setQuery({ ...query, isDiscounted: false });
          }}
        />
      )}
      {query.isSoldOut && (
        <FilterPill
          label={'Include sold'}
          onRemove={() => {
            setQuery({ ...query, isSoldOut: false });
          }}
        />
      )}
    </div>
  );
}
