import React, { useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';

export type OutletContext = {
  scrollTop: React.MutableRefObject<number>;
};

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const scrollTop = useRef<number>(0);

  return (
    <div>
      <Header toggleMenu={toggleMenu} />
      <div className={`${isLandingPage ? '' : 'pt-24'} bg-background `}>
        <Outlet
          context={
            {
              scrollTop: scrollTop,
            } satisfies OutletContext
          }
        />
      </div>
      <Menu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <Footer />
    </div>
  );
};

export default App;
