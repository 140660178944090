import React from 'react';
import { FaBalanceScaleLeft } from 'react-icons/fa';

interface Props {
  selectedProducts: number;
  canStartComparing: boolean;
  startComparing: () => void;
  clearSelectedProducts: () => void;
}

const CompareFooter = ({
  selectedProducts,
  canStartComparing,
  startComparing,
  clearSelectedProducts,
}: Props) => {
  return (
    <div className="flex fixed bottom-0 w-full bg-white h-16 px-2">
      <div className="flex-1"></div>
      <div className="flex flex justify-center items-center">
        <button
          disabled={!canStartComparing}
          onClick={startComparing}
          className={`${
            canStartComparing
              ? 'bg-black text-white'
              : 'bg-black text-white opacity-50'
          } border rounded-md p-2 px-4 font-sans`}
        >
          <FaBalanceScaleLeft className="inline-block mr-2 text-lg" />
          <span>{`Compare products (${selectedProducts}/2)`}</span>
        </button>
      </div>
      <div className="flex-1 flex justify-end items-center">
        <button
          onClick={clearSelectedProducts}
          className="text-black p-2 font-sans text-sm"
        >
          <span>Clear All</span>
        </button>
      </div>
    </div>
  );
};

export default CompareFooter;
