import React from 'react';
import { LuSearch, LuSearchX } from 'react-icons/lu';

interface Props {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
}

export default function Search({ searchInput, setSearchInput }: Props) {
  return (
    <div className="flex relative items-center mb-2 w-full mx-auto">
      <input
        type="text"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        placeholder="Search"
        className="rounded-full p-1 font-sans border shadow px-4 w-full"
      />
      {searchInput ? (
        <LuSearchX
          onClick={() => setSearchInput('')}
          className="absolute right-3 cursor-pointer text-gray-500"
          size={20}
        />
      ) : (
        <LuSearch
          className="absolute right-3 cursor-pointer text-gray-500"
          size={20}
        />
      )}
    </div>
  );
}
