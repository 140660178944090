import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function ClickPropagationBlocker({ children }: Props) {
  return (
    <span
      onClick={evt => {
        evt.preventDefault();
        evt.nativeEvent.stopImmediatePropagation();
        evt.stopPropagation();
      }}
    >
      {children}
    </span>
  );
}
