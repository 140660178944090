import React, { useEffect, useState } from 'react';
import { Product } from '../lib/types';
import { MdOutlineDiscount } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { RiHeartAdd2Line } from 'react-icons/ri';
import { RiHeart3Fill } from 'react-icons/ri';
import ClickPropagationBlocker from './common/ClickPropagationBlocker';

interface Props {
  product: Product;
  isSelected: boolean;
  handleSelectProduct: (product: Product) => void;
}

const Card = ({ product, isSelected, handleSelectProduct }: Props) => {
  const [isLiked, setIsLiked] = useState<boolean>(false);

  const hasDiscount = parseInt(product.discount) > 0;
  const isSold = product.sold;

  useEffect(() => {
    const likedProducts =
      JSON.parse(localStorage.getItem('likedProducts') || '[]') || [];
    setIsLiked(likedProducts.includes(product.id));
  }, [product.id]);

  const toggleHeart = () => {
    const likedProducts: string[] =
      JSON.parse(localStorage.getItem('likedProducts') || '[]') || [];
    if (isLiked) {
      const updatedProducts = likedProducts.filter(id => id !== product.id);
      localStorage.setItem('likedProducts', JSON.stringify(updatedProducts));
    } else {
      likedProducts.push(product.id);
      localStorage.setItem('likedProducts', JSON.stringify(likedProducts));
    }
    setIsLiked(!isLiked);
  };

  const isNewProduct = () => {
    const now = new Date();
    const productDate = new Date(product.createdAt.seconds * 1000);
    const timeDifference = now.valueOf() - productDate.valueOf();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference < 24;
  };

  let discountPercentage = 0.0;
  if (hasDiscount) {
    discountPercentage =
      (parseFloat(product.price) - parseFloat(product.discount)) /
      parseFloat(product.price);
  }

  return (
    <div
      className={`overflow-hidden flex flex-col h-full hover:cursor-pointer ${
        isSelected ? 'border-compareColor border rounded-lg shadow-md' : ''
      }`}
    >
      <div className="relative">
        <div className="aspect-square overflow-hidden rounded-lg bg-white">
          <img
            // loading="lazy"
            src={product.imageUrl}
            className="w-full h-full transition-transform duration-300 ease-in-out hover:scale-105 rounded"
            alt=""
          />
        </div>
        {isNewProduct() && (
          <p className="font-sans text-[12px] font-semibold p-1 absolute top-2 left-2 rounded-full px-2 bg-yellow-400 text-black">
            New
          </p>
        )}
        {isSold ? (
          <p className="font-sans text-[12px] font-semibold p-1 absolute top-2 right-2 rounded-full px-2 bg-gray-300 text-black">
            Sold
          </p>
        ) : hasDiscount ? (
          <div className="flex font-sans text-[12px] font-semibold p-1 absolute top-2 right-2 rounded-full px-2 bg-red-400 text-white align-middle items-center">
            <MdOutlineDiscount className="mr-1 text-[14px]" />
            <p>
              {discountPercentage.toLocaleString('en-US', {
                style: 'percent',
                minimumFractionDigits: 0,
              })}
              {'  '}
            </p>
          </div>
        ) : null}
        <ClickPropagationBlocker>
          <button
            className={`absolute bottom-2 right-2 ${
              isLiked ? 'text-heartColor' : 'text-black'
            } bg-white rounded-full p-2 drop-shadow hover:border border-gray-400`}
            onClick={toggleHeart}
          >
            {isLiked ? (
              <RiHeart3Fill className="text-xl" />
            ) : (
              <RiHeartAdd2Line className="text-xl" />
            )}
          </button>
        </ClickPropagationBlocker>
      </div>
      <div className="flex flex-grow flex-row text-sm sm:text-sm md:text-md lg:text-lg py-1 px-2">
        <div className="flex flex-col flex-grow leading-normal">
          <div className="flex flex-col flex-grow">
            <p className="font-serif font-medium text-[20px] uppercase">
              {product.brand}
            </p>
            <p className="font-sans text-[14px]">{product.model}</p>
            <p className="font-sans text-[14px]">{product.condition}</p>
            {hasDiscount ? (
              <div>
                <p className="font-sans text-[14px] font-semibold mt-1 mr-2 text-gray-500 line-through decoration-1">
                  {parseFloat(product.price).toLocaleString('se-SE') +
                    ' ' +
                    product.currency}
                </p>
                <p className="font-sans text-[14px] font-semibold  mr-2 text-red-500">
                  {parseFloat(product.discount).toLocaleString('se-SE') +
                    ' ' +
                    product.currency}
                </p>
              </div>
            ) : (
              <div className="mt-1">
                <p className="font-sans text-[14px] font-semibold ">
                  {parseFloat(product.price).toLocaleString('se-SE') +
                    ' ' +
                    product.currency}
                </p>
              </div>
            )}
            <p className="font-sans text-[14px] italic text-gray-400 mt-2">
              By {product.store}
            </p>
          </div>
        </div>
      </div>
      <ClickPropagationBlocker>
        <div
          className="flex font-sans text-sm py-1 px-2 align-middle"
          onClick={() => handleSelectProduct(product)}
        >
          <input
            key={`${product.id + isSelected.toString()}`}
            type="checkbox"
            checked={isSelected}
            readOnly
            className="accent-black"
          />
          <label className="ml-2 cursor-pointer">Compare</label>
        </div>
      </ClickPropagationBlocker>
    </div>
  );
};

export default Card;
