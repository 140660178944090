import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

interface MenuItemProps {
  to: string;
  label: string;
  onClick: () => void;
}

const MenuItem = ({ to, label, onClick }: MenuItemProps) => {
  return (
    <Link
      to={to}
      className="flex justify-between items-center py-2 border-gray-200"
      onClick={onClick}
      reloadDocument
    >
      <span>{label}</span>
      {/* <HiArrowLongRight /> */}
    </Link>
  );
};

interface MenuItemLinkProps {
  label: string;
  url: string;
  onClick: () => void;
}

const MenuItemLink = ({ label, url, onClick }: MenuItemLinkProps) => {
  return (
    <a
      href={url}
      className="flex justify-between items-center py-2 text-lg border-gray-200"
      onClick={onClick}
    >
      <span>{label}</span>
    </a>
  );
};

interface SubmenuProps {
  title: string;
  children: ReactNode;
}

const Submenu = ({ title, children }: SubmenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="cursor-pointer border-b" onClick={toggle}>
      <div className="flex justify-between items-center px-4 mt-4 mb-2">
        <h2 className="text-lg font-sans">{title}</h2>
        <div>
          {isOpen ? (
            <RiArrowUpSLine className="text-xl" />
          ) : (
            <RiArrowDownSLine className="text-xl" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-col bg-gray-100 w-full pl-4 italic">
          {children}
        </div>
      )}
    </div>
  );
};

interface Props {
  isOpen: boolean;
  toggleMenu: () => void;
}

const Menu = ({ isOpen, toggleMenu }: Props) => {
  return (
    <div>
      <div
        className={`justify-between fixed inset-y-0 left-0 bg-white w-80  transition-transform duration-300 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } z-50 shadow-lg`}
      >
        <div className="flex flex-col">
          <div>
            <Submenu title={'Bags'}>
              <MenuItem to="/products" label="All bags" onClick={toggleMenu} />
              <MenuItem
                to="/products?category=hand-bag"
                label="Handbags"
                onClick={toggleMenu}
              />
              <MenuItem
                to="/products?category=shoulder-bag"
                label="Shoulder bags"
                onClick={toggleMenu}
              />
              <MenuItem
                to="/products?category=crossbody-bag"
                label="Crossbody bags"
                onClick={toggleMenu}
              />
              <MenuItem
                to="/products?category=tote-bag"
                label="Totes"
                onClick={toggleMenu}
              />
              <MenuItem
                to="/products?category=weekend-bag"
                label="Weekend bags"
                onClick={toggleMenu}
              />
              <MenuItem
                to="/products?category=clutch"
                label="Clutch bags"
                onClick={toggleMenu}
              />
              <MenuItem
                to="/products?category=belt-bag"
                label="Belt bags"
                onClick={toggleMenu}
              />
              <MenuItem
                to="/products?category=backpack"
                label="Backpacks"
                onClick={toggleMenu}
              />
            </Submenu>
          </div>
          <div className="pl-4 pt-2 text-lg">
            <MenuItem to="/brands" label="Brands" onClick={toggleMenu} />
            <MenuItem
              to="/products?sort=createdAt"
              label="New In"
              onClick={toggleMenu}
            />
            <MenuItem
              to="/products?discounted=true"
              label="Sale"
              onClick={toggleMenu}
            />
          </div>
          <div className="mt-4 pl-4">
            <MenuItemLink
              label="Blog"
              url="https://blog.avenura.com"
              onClick={toggleMenu}
            />
            <MenuItemLink
              label="Newsletter"
              url="https://blog.avenura.com/newsletter"
              onClick={toggleMenu}
            />
            <MenuItemLink
              label="Partners"
              url="https://blog.avenura.com/partners"
              onClick={toggleMenu}
            />
            <MenuItemLink
              label="About Us"
              url="https://blog.avenura.com/about"
              onClick={toggleMenu}
            />
            {/* <MenuItemLink
              label="Privacy Policy"
              url="https://blog.avenura.com/privacy-policy"
              onClick={toggleMenu}
            />
            <MenuItemLink
              label="Terms & Conditions"
              url="https://blog.avenura.com/terms-conditions"
              onClick={toggleMenu}
            /> */}
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-40 ${
          isOpen ? 'block' : 'hidden'
        }`}
        onClick={toggleMenu}
      ></div>
    </div>
  );
};

export default Menu;
