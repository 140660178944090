import { useEffect, useRef, useState } from 'react';
import { db } from '../lib/firebase';
import {
  collection,
  getDocs,
  limit,
  query as firestoreQuery,
  where,
} from 'firebase/firestore';
import ScrollToTop from '../components/ScrollToTop';
import { Product } from '../lib/types';
import Grid from '../components/products/Grid';
import FilterMenu from '../components/FilterMenu';
import {
  useNavigationType,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import Compare from '../components/Compare';
import { categoryMap } from '../lib/types';
import CompareFooter from '../components/CompareFooter';
import { useMediaQuery } from 'react-responsive';
import FilterBar from '../components/FilterBar';
import Pagination from '../components/Pagination';
import Search from '../components/products/Search';
import {
  createQueryFromSearchParams,
  createSearchParamsFromQuery,
  defaultQuery,
  Query,
  search,
} from '../lib/search';
import FilterButton from '../components/products/FilterButton';
import SortDropdown from '../components/products/SortDropdown';
import FilterPills from '../components/products/FilterPills';
import GridPlaceholder from '../components/products/GridPlaceholder';
import { OutletContext } from '../App';

export default function ProductsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { scrollTop } = useOutletContext<OutletContext>();
  const navigationType = useNavigationType();
  const [back, setBack] = useState<boolean>(false);

  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<Query>(defaultQuery);
  const productsPerPage = 30;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [totalPages, setTotalPages] = useState<number>(0);

  const [searchInput, setSearchInput] = useState<string>('');
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [showCompare, setShowCompare] = useState<boolean>(false);
  const isComparing = selectedProducts.length > 0;
  const canCompare = selectedProducts.length === 2;

  const isMounted = useRef(false);

  const fetchData = async (query: Query) => {
    setProducts([]);
    setLoading(true);
    const { hits: ids, totalPages } = await search(
      query,
      query.page,
      productsPerPage
    );
    if (ids.length === 0) {
      setLoading(false);
      return;
    }

    const productsQuery = firestoreQuery(
      collection(db, 'products'),
      where('__name__', 'in', ids),
      limit(productsPerPage)
    );
    const snapshot = await getDocs(productsQuery);
    const products = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    })) as Product[];
    setProducts(products);
    setTotalPages(totalPages);
    setLoading(false);
  };

  useEffect(() => {
    console.log('mounting begin');
    if (navigationType === 'POP') setBack(true);
    console.log('searchParams:', searchParams);
    const query = createQueryFromSearchParams(searchParams);
    setQuery(query);
    setSearchInput(query.search);
    fetchData(query);
    return () => {
      console.log('unmounting');
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      console.log('tried to update search params');
      return;
    }

    console.log('updated search params and fetch data');

    const params = createSearchParamsFromQuery(query);
    setSearchParams(params, { replace: true });
    fetchData(query);
  }, [query]);

  useEffect(() => {
    if (!isMounted.current) {
      console.log('tried to fetch data from search field');
      return;
    }

    console.log('fetch data from search field');

    if (searchInput === '') {
      setQuery({ ...query, search: searchInput });
      fetchData(query);
    } else {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        if (searchInput) {
          setQuery({ ...query, search: searchInput });
          fetchData(query);
        }
      }, 1000);
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchInput]);

  useEffect(() => {
    console.log('mounted done');
    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (!loading) {
      if (back) {
        console.log('scrolling to last product');
        console.log(scrollTop);
        window.scrollTo(0, scrollTop.current);
        setBack(false);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [loading, back]);

  const handleSelectProduct = (product: Product) => {
    setSelectedProducts(prev => {
      if (prev.some(p => p.id === product.id)) {
        return prev.filter(p => p.id !== product.id);
      } else {
        if (prev.length >= 2) {
          return prev;
        }
        return [...prev, product];
      }
    });
  };

  const handleCompareClick = () => {
    setShowCompare(true);
  };

  const handleCloseCompare = () => {
    setShowCompare(false);
    setSelectedProducts([]);
  };

  const handlePageChange = (page: number) => {
    setQuery({ ...query, page: page });
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  };

  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  return (
    <div className={`min-h-screen flex flex-col pb-16`}>
      <main className="flex-grow p-4 container mx-auto">
        <Search searchInput={searchInput} setSearchInput={setSearchInput} />

        <h1 className="text-3xl font-serif mt-4 mb-2 text-center">
          {query.category ? categoryMap[query.category.value] : 'All Products'}
        </h1>
        <div className="flex flex-row mb-2 items-center justify-between">
          {false ? (
            <div className="my-2">
              <FilterBar query={query} setQuery={setQuery} />
            </div>
          ) : (
            <FilterButton toggleFilter={toggleFilter} />
          )}
          <SortDropdown
            label={query.sortBy?.label}
            query={query}
            setQuery={setQuery}
          />
        </div>

        <FilterMenu
          query={query}
          setQuery={setQuery}
          isOpen={isFilterOpen}
          close={() => {
            setIsFilterOpen(false);
          }}
        />

        <div className="mb-4">
          <FilterPills query={query} setQuery={setQuery} />
        </div>

        {loading ? (
          <GridPlaceholder productsPerPage={productsPerPage} />
        ) : products.length > 0 ? (
          <>
            <Grid
              products={products}
              selectedProducts={selectedProducts}
              handleSelectProduct={handleSelectProduct}
              scrollTop={scrollTop}
            />
            <Pagination
              totalPages={totalPages}
              currentPage={query.page}
              onPageChange={handlePageChange}
            />
            <ScrollToTop isComparing={isComparing} />
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <p className="font-sans">{`No products found :(`}</p>
          </div>
        )}

        {showCompare && (
          <Compare
            products={selectedProducts}
            handleClose={handleCloseCompare}
          />
        )}
      </main>

      {isComparing && (
        <CompareFooter
          selectedProducts={selectedProducts.length}
          canStartComparing={canCompare}
          startComparing={handleCompareClick}
          clearSelectedProducts={() => setSelectedProducts([])}
        />
      )}
    </div>
  );
}
