import React, { ReactNode, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

interface Props {
  images: string[];
  children?: ReactNode;
}

export default function Carousel({ images, children }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const goToNext = () => {
    if (isTransitioning) return; // Prevent double triggering
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
      setIsTransitioning(false);
    }, 300); // 300ms matches the CSS transition duration
  };

  const goToPrevious = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(
        prevIndex => (prevIndex - 1 + images.length) % images.length
      );
      setIsTransitioning(false);
    }, 300);
  };

  const goToIndex = (index: number) => {
    if (isTransitioning) return;
    setCurrentIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: goToNext,
    onSwipedRight: goToPrevious,
    trackMouse: true,
  });

  return (
    <div className="relative w-full mx-auto overflow-hidden" {...handlers}>
      <div
        className="relative flex transition-transform duration-300 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className="w-full flex-shrink-0 object-cover"
          />
        ))}
      </div>

      {children}

      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        onClick={goToPrevious}
      >
        &#10094;
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        onClick={goToNext}
      >
        &#10095;
      </button>

      <div className="absolute bottom-4 w-full flex justify-center space-x-2">
        {images.map((_, index) => (
          <span
            key={index}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              index === currentIndex ? 'bg-gray-800' : 'bg-gray-400'
            }`}
            onClick={() => goToIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}
